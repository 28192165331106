/**
* Template Name: Bootslander - v4.6.0
* Template URL: https://bootstrapmade.com/bootslander-free-bootstrap-landing-page-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
  margin: 0;
  padding: 0;
}

a {
  color: #1acc8d;
  text-decoration: none;
}

a:hover {
  color: #34e5a6;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}
#h_image {
  width: 82%;
  margin-left: 15%;
  border: none;
  margin-bottom: 8%;
  border-radius: 30px;
}

#preloader:before {
  content: '';
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #1acc8d;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}
.donate {
  border: none;
  border-radius: 33px;
  padding: 2% 8%;
  background-color: #eacc0d;
  color: white;
}

h2#donations {
  color: #1acc8d !important;
  font-size: 16px;
}

.reg:hover {
  background: #e87e12;
}
@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.numbers {
  font-size: 280%;
  font-weight: 900;
  color: #889aa5;
}
.plus {
  font-size: 120%;
}

@media screen and (max-width: 900px) {
  #h_image {
    width: 91%;
    margin-left: 4%;
    margin-bottom: -6%;
  }
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #1acc8d;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #2be4a2;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 80px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(1, 4, 136, 0.9);
}
#header.header-transparent {
  background: transparent;
}
#header.header-scrolled {
  background: #2b6777;
  filter: brightness(80%);
  opacity: 0.9;
  height: 60px;
}
#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}
#header .logo h1#coc {
  font-size: 20px;
  margin: 10px 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 3px;
  left: 30px;
  background-color: #1acc8d;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 25px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #01036f;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #1acc8d;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 3, 91, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile > ul > li > a:before {
  left: 20px;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0205a1;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #3f43fd;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #1acc8d;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  background: url('../img/hero-bg.webp');
  position: relative;
  padding: 120px 0 0 0;
}
#hero:before {
  content: '';
  background: #52ab98;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
}
#hero h1 span {
  color: #fff;
  border-bottom: 4px solid #eacc0d;
  text-align: center;
}
#hero h2 {
  text-align: center;
}
#hero h2 {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  font-size: 24px;
}
#intro {
  font-style: normal;
  text-align: left;
}
#hl {
  background-color: #bfbf12;
  width: 40%;
  padding: 1%;
  margin-left: 30%;
  margin-top: 2%;
}
#hero .btn-get-started {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-left: 2%;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #eacc0d;
}
#hero .btn-get-started:hover {
  background: #17b57d;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  #hero {
    padding-top: 80px;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .btn-get-started {
    margin-left: 4%;
    margin-bottom: 3%;
  }
  .dsc_len {
    width: 99%;
  }
  .together {
    display: block;
  }
  .reg {
    width: 70%;
    width: 68%;
  }
  #hl {
    width: 87%;
    margin-left: 6%;
  }
  .section-title p {
    font-size: 25px;
  }
  .partner_img {
    width: 61%;
  }
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
    margin-left: 0%;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    max-width: 50%;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 900px) {
  #hero .btn-get-started {
    margin-left: 4%;
    margin-bottom: 3%;
  }
  .dsc_len {
    width: 99%;
  }
  .together {
    display: block;
  }
  .reg {
    width: 70%;
  }
  #hl {
    width: 87%;
    margin-left: 6%;
  }
  .section-title p {
    font-size: 25px;
  }
  .partner_img {
    width: 61%;
  }
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
    margin-left: 0%;
  }
}
@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
  .counts .count-box {
    margin-left: 0%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f5ff;
}

.section-title {
  padding-bottom: 40px;
}

/* OLD STYLE, overlapped on iPhone SE*/
/* .section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
} */
.section-title h2 {
  font-size: 70%;
  font-weight: 500;
  padding: 0;
  line-height: 10px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  /* white-space: nowrap; */
}

/* IPHONE SE STYLING */
@media screen and (max-width: 400px) {
  .intro-to-finance{
    width: 85%;
  }

  #volunteer-fair img{
    width: 85%;
  }

  #compass-ride img{
    width: 85%;
  }

  #intro-to-finaince img{
    width: 85%;
  }

  #compass-codes img{
    width: 85%;
  }
}

#compass-codes img{
  max-height: 300px;
}

.section-title h2::after {
  content: '';
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #1acc8d;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #eacc0d;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #fafaff;
  min-height: 40px;
  margin-top: 80px;
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 64px;
  }
}
.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: '/';
}
@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  padding: 40px 0 0 0;
}
.about .icon-boxes h3 {
  font-size: 28px;
  font-weight: 700;
  color: #eacc0d;
  margin-bottom: 15px;
}
.about .icon-box {
  margin-top: 40px;
}
.about .icon-box .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border: 2px solid #7ceec6;
  border-radius: 50px;
  transition: 0.5s;
}
.about .icon-box .icon i {
  color: #1acc8d;
  font-size: 32px;
}
.about .icon-box:hover .icon {
  background: #1acc8d;
  border-color: #1acc8d;
}
.about .icon-box:hover .icon i {
  color: #fff;
}
.about .icon-box .title {
  margin-left: 85px;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}
.about .icon-box .title a {
  color: #2b6777;
  transition: 0.3s;
}
.about .icon-box .title a:hover {
  color: #2b6777;
}
.about .icon-box .description {
  margin-left: 85px;
  line-height: 24px;
  font-size: 14px;
}
.about .video-box {
  background: url('../img/about.webp') center center no-repeat;
  background-size: contain;
  min-height: 300px;
}
.about .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#3f43fd 50%, rgba(63, 67, 253, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}
.about .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.about .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(63, 67, 253, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}
.about .play-btn:hover::after {
  border-left: 15px solid #3f43fd;
  transform: scale(20);
}
.about .play-btn:hover::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-left: 2%;
  background: #f5f5ff;
  transition: ease-in-out 0.3s;
}
.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}
.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}
.features .icon-box h3 a {
  color: #2b6777;
  transition: ease-in-out 0.3s;
}
.features .icon-box h3 a:hover {
  color: #2b6777;
}
.features .icon-box:hover {
  background: #ebebff;
}
.dsc_len {
  width: 50%;
}
.seperate {
  display: block;
}
.together {
  display: flex;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  background: #f5f5ff;
  padding: 70px 0 60px;
}
.counts .count-box {
  padding: 30px 30px 25px 30px;
  width: 100%;
  margin-left: 55%;
  position: relative;
  text-align: center;
  background: #fff;
}
.counts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  background: #2b6777;
  padding: 12px;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  width: 48px;
  height: 48px;
}
.counts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #010483;
}
.counts .count-box p {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

@media screen and (max-width: 900px) {
  #hero .btn-get-started {
    margin-left: 4%;
    margin-bottom: 3%;
  }
  .dsc_len {
    width: 99%;
  }
  .together {
    display: block;
  }
  .reg {
    width: 68%;
  }
  #hl {
    width: 87%;
    margin-left: 6%;
  }
  .section-title p {
    font-size: 25px;
  }
  .partner_img {
    width: 61%;
  }
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
    margin-left: 0%;
  }
}

/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/
.details .content + .content {
  margin-top: 100px;
}
.details .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #010483;
}
.details .content ul {
  list-style: none;
  padding: 0;
}
.details .content ul li {
  padding-bottom: 10px;
}
.details .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #1acc8d;
}
.details .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery .gallery-item {
  overflow: hidden;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}
.gallery .gallery-item img {
  transition: all ease-in-out 0.4s;
}
.gallery .gallery-item:hover img {
  transform: scale(1.1);
}
.partner_img {
  width: 30%;
}
#compass {
  width: 20%;
}

/*--------------------------------------------------------------
# News
--------------------------------------------------------------*/
.news {
  padding: 80px 0;
  /*  background: url("https://upload.wikimedia.org/wikipedia/commons/f/fb/Light_blue.png") no-repeat;
*/
  background-position: center center;
  background-color: #fff;
  background-size: cover;
  position: relative;
  margin-bottom: 2%;
  filter: brightness(100%);
}

.news .section-title h2 {
  font-size: 16px;
  color: #1acc8d;
  font-weight: 600;
  margin-bottom: 20px;
}

.news .testimonials-carousel,
.mission,
.news .testimonials-slider {
  overflow: hidden;
}
.news .testimonial-item,
.mission {
  text-align: center;
  color: #ffffff;
}
.news .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}
.news .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff !important;
}
.news .testimonial-item h4 {
  font-size: 14px;
  color: #fff;
  margin: 0 0 15px 0;
}
.news .testimonial-item .quote-icon-left,
.news .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}
.news .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.news .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.news .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #ffff;
}
.news .testimonial-item p.non-ti {
  font-style: normal !important;
}
.news .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.news .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}
.news .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1acc8d;
}
@media (min-width: 1024px) {
  .testimonials,
  .mission {
    background-attachment: fixed;
  }
}
@media (min-width: 992px) {
  .testimonials .testimonial-item p,
  .mission {
    width: 80%;
  }
  .counts .count-box {
    margin-left: 55%;
  }
}

/*--------------------------------------------------------------
# Mission
--------------------------------------------------------------*/
.testimonials {
  padding: 80px 0;
  /*  background: url("https://upload.wikimedia.org/wikipedia/commons/f/fb/Light_blue.png") no-repeat;
*/
  background-position: center center;
  background-color: #2b6777;
  background-size: cover;
  position: relative;
  margin-bottom: 2%;
  filter: brightness(100%);
}

.testimonials.events h3 {
  color: white;
  border-radius: 5px;
}
.testimonials.events h3 a {
  color: white;
}

.mission {
  padding: 80px 0;
  background: url('../img/cta-bg.webp') no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  margin-bottom: 2%;
}
.testimonials::before,
.mission::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /*  background: rgba(1, 3, 111, 0.8);
*/
}
.testimonials .section-header,
.mission {
  margin-bottom: 40px;
}
.testimonials .testimonials-carousel,
.mission,
.testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item,
.mission {
  text-align: center;
  color: #ffffff;
}
.testimonials .testimonial-item .testimonial-img {
  width: 100px;
  border-radius: 50%;
  border: 6px solid rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}
.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #fff !important;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #fff;
  margin: 0 0 15px 0;
}
.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: rgba(255, 255, 255, 0.4);
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
  color: #ffff;
}
.testimonials .testimonial-item p.non-ti {
  font-style: normal !important;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.4);
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #1acc8d;
}
@media (min-width: 1024px) {
  .testimonials,
  .mission {
    background-attachment: fixed;
  }
}
@media (min-width: 992px) {
  .testimonials .testimonial-item p,
  .mission {
    width: 80%;
  }
  .counts .count-box {
    margin-left: 55%;
  }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team {
  background: #fff;
}
.team .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.team .member .pic {
  border-radius: 4px;
  overflow: hidden;
}
.tmpSize {
  height: 500px !important;
}
.team .member img {
  width: 100%;
  object-fit: cover;
  transition: all ease-in-out 0.4s;
}
.team .member:hover img {
  transform: scale(1.1);
}
.team .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.team .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #01036f;
  position: relative;
  padding-bottom: 10px;
}
.team .member h4::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 25px);
}
.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #01036f;
}
.team .member .social {
  margin-top: 10px;
}
.team .member .social a {
  transition: color 0.3s;
  color: #2b6777;
}
.team .member .social a:hover {
  color: #2b6777;
}
.team .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Board of Directors
--------------------------------------------------------------*/
.board {
  background: #fff;
}
.board .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.board .member .pic {
  border-radius: 4px;
  overflow: hidden;
}
.board .member img {
  transition: all ease-in-out 0.4s;
}
.board .member:hover img {
  transform: scale(1.1);
}
.board .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.board .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #01036f;
  position: relative;
  padding-bottom: 10px;
}
.board .member h4::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 25px);
}
.board .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #01036f;
}
.board .member .social {
  margin-top: 10px;
}
.board .member .social a {
  transition: color 0.3s;
  color: #2b6777;
}
.board .member .social a:hover {
  color: #2b6777;
}
.board .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 5%;
}
.pricing h2 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 30px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}
.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 30px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}
.pricing h4 {
  font-size: 36px;
  color: #1acc8d;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}
.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}
.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}
.pricing ul li {
  padding-bottom: 16px;
}
.pricing ul i {
  color: #1acc8d;
  font-size: 18px;
  padding-right: 4px;
}
.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}
.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}
.pricing .btn-buy {
  background: #1acc8d;
  display: inline-block;
  padding: 8px 35px 10px 35px;
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  transition: 0.3s;
}
.pricing .btn-buy:hover {
  background: #149f6e;
}
.pricing .featured h2 {
  color: #fff;
  background: #52ab98;
}
.pricing .featured h3 {
  color: #fff;
  background: #52ab98;
}
.pricing .advanced {
  width: 200px;
  margin-bottom: 3%;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #1acc8d;
  color: #fff;
}
.subjects {
  width: 40%;
  margin: 2%;
  margin-bottom: 10%;
}
#int {
  width: 38%;
}
.lrn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 7px 30px;
  margin-top: -4%;
  border: none;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #eacc0d;
}
.reg {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1px;
  padding: 10px;
  border: none;
  width: 300px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #eacc0d;
  margin: 0 auto;
}
.icons {
  width: 40%;
  margin-bottom: 8%;
}
h4.vol {
  font-size: 26px;
}
@media screen and (max-width: 900px) {
  #hl {
    width: 87%;
    margin-left: 6%;
  }
  .section-title p {
    font-size: 25px;
  }
  .partner_img {
    width: 61%;
  }
}
/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0;
}
.faq .faq-list ul {
  padding: 0;
  list-style: none;
}
.faq .faq-list li + li {
  margin-top: 15px;
}
.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.faq .faq-list a {
  display: block;
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}
.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #eacc0d;
}
.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.faq .faq-list .icon-show {
  display: none;
}
.faq .faq-list a.collapsed {
  color: #eacc0d;
}
.faq .faq-list a.collapsed:hover {
  color: #eacc0d;
}
.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}
.faq .faq-list a.collapsed .icon-close {
  display: none;
}
@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  #hl {
    width: 87%;
    margin-left: 6%;
  }
  .section-title p {
    font-size: 25px;
  }
  .partner_img {
    width: 61%;
  }
}
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
  margin-bottom: 100px;
}
.contact .info i {
  font-size: 20px;
  color: #eacc0d;
  float: left;
  width: 44px;
  height: 44px;
  background: #f0f0ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #eacc0d;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #eacc0d;
}
.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}
.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #eacc0d;
  color: #fff;
}
.contact .php-email-form {
  width: 100%;
  background: #fff;
}
.contact .php-email-form .form-group {
  padding-bottom: 8px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
.contact .php-email-form input {
  height: 44px;
}
.contact .php-email-form textarea {
  padding: 10px 12px;
}
.contact .php-email-form button[type='submit'] {
  background: #1acc8d;
  border: 0;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .php-email-form button[type='submit']:hover {
  background: #34e5a6;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #2b6777;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
}
#footer .footer-top .footer-info {
  margin-bottom: 15px;
  background: #52ab98;
  color: #fff;
  border-top: 4px solid #52ab98;
  text-align: center;
  padding: 30px 20px;
  width: 70%;
  min-width: 400px;
}
#footer .footer-top .footer-info a {
  color: #fff;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: 'Montserrat', sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #1acc8d;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #61ebba;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #4be8b0;
}
#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px 6px 15px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-top .footer-newsletter form input[type='email'] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type='submit'] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #1acc8d;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}
#footer .footer-top .footer-newsletter form input[type='submit']:hover {
  background: #149f6e;
}
#footer .copyright {
  border-top: 1px solid #eacc0d;
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}

#google_translate_element {
  position: sticky;
  bottom: 20px;
  margin-left: 10px;
  margin-top: 100px;
  /* border: 1px solid black; */
  width: max-content;
}


/* UNIVERSITY PANEL INFO PAGE */
.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.5rem;
}

.check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
}


/* Boilerplate stuff */
*:before,
*:after {
  box-sizing: border-box;
}



/* Panel Styles */
.panel-text {
  padding-left: 25%;
  padding-right: 25%;
}
@media screen and (max-width: 600px) {
  .panel-text {
    padding:0;
  }
}

/* FAQ styles */
.content {
  border: 1px solid rgb(214, 24, 24);
  padding: 0.5rem;
  border-radius: 5px;
}
  
.content-parent {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.9s;
}
  
.content-show {
  height: 200px;
}